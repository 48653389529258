import "./css/output.css";
import gsap from "gsap";
import { Observer } from "gsap/Observer";
import "./model-viewer.min.js";

gsap.registerPlugin(Observer);

async function sectionAnimation() {
  

let sections = document.querySelectorAll("section"),
  images = document.querySelectorAll(".bg"),
  nextBtn = document.querySelectorAll(".outer-before"),
  headings = gsap.utils.toArray(".section-heading"),
  outerWrappers = gsap.utils.toArray(".outer"),
  innerWrappers = gsap.utils.toArray(".inner"),
  splitHeadings = gsap.utils.toArray(".section-heading"),
  currentIndex = -1,
  loadOnce = false,
  wrap = gsap.utils.wrap(0, sections.length - 1),
  animating;
//console.log(splitHeadings);

gsap.set(outerWrappers, { yPercent: 100 });
gsap.set(innerWrappers, { yPercent: -100 });

gsap.set(".section-2 .card-outer", { yPercent:'-150' , opacity:0,})

function gotoSection(index, direction) {
  index = wrap(index); // make sure it's valid
  animating = true;
  let fromTop = direction === -1,
      dFactor = fromTop ? -1 : 1,
      tl = gsap.timeline({
        defaults: { duration: .5, ease: "power1.inOut" },
        onComplete: () => animating = false
      });

  if (index === 0) {
    // The first time this function runs, current is -1
    gsap.set(".section-1__introtext", { xPercent:'-150', opacity:0})
    gsap.set(".section-1 model-viewer", { yPercent:'150', scale:'0' , opacity:0, duration: .5});
    tl.to(".section-1__introtext", { xPercent:'0', opacity:1, duration: 1.25})
    .to(".section-1 model-viewer", { yPercent:'0', scale:'1', opacity:1, duration: .5});
  } else {
    if (loadOnce) {
          tl.to(".section-1__introtext", { xPercent:'-150', opacity:0, duration: 1.25 })
          .to(".section-1 model-viewer", { yPercent:'150', scale:'0', opacity:0, duration: .5});
    }
  }

  if (index === 1) {
    // The first time this function runs, current is -1
    gsap.set(".section-2 .card-outer", { yPercent:'50' , opacity:0});
    tl.to(".section-2 .card-outer-1", { yPercent:'0', opacity:1})
      .to(".section-2 .card-outer-2", { yPercent:'0', opacity:1})
      .to(".section-2 .card-outer-3", { yPercent:'0', opacity:1})
  } else {
    if (loadOnce) {
    tl.to(".section-2 .card-outer-1", { yPercent:'-50', opacity:0})
      .to(".section-2 .card-outer-2", { yPercent:'-50', opacity:0})
      .to(".section-2 .card-outer-3", { yPercent:'-50', opacity:0})
    }
  }

  if (index === 2) {
    // The first time this function runs, current is -1
    gsap.set(".section-3 .card", { xPercent:'-150' , opacity:0});
    gsap.set(".section-3 .hero-content-text", { yPercent:'150' , opacity:0, duration: 1});
    gsap.set(".section-3 model-viewer", { scale:'0' , opacity:0, duration: .5});

    tl.to(".section-3 .card", { xPercent:'0', opacity:1, duration: 1})
    .to(".section-3 .hero-content-text", { yPercent:'0', opacity:1, duration: 1})
    .to(".section-3 model-viewer", { scale:'1', opacity:1, duration: .5});
  } else {
    if (loadOnce) {
    tl.to(".section-3 .card", { xPercent:'-150', opacity:0, duration: 1})
    .to(".section-3 .hero-content-text", { yPercent:'150', opacity:0, duration: 1})
    .to(".section-3 model-viewer", { scale:'0', opacity:0, duration: .5});
    }
  }

  if (index === 3) {
    // The first time this function runs, current is -1
    gsap.set(".section-4 .contact-info", { xPercent:'-150' , opacity:0});
    gsap.set(".section-4 model-viewer", { scale:'0', opacity:0, duration: .5});
    tl.to(".section-4 .contact-info", { xPercent:'0', opacity:1, duration: 1});
    tl.to(".section-4 model-viewer", { scale:'1', opacity:1, duration: .5});
  } else {
    if (loadOnce) {
    tl.to(".section-4 .contact-info", { xPercent:'-150', opacity:0, duration: 1});
    tl.to(".section-4 model-viewer", { scale:'0', opacity:0, duration: .5});
    }
  }

  //console.log(index)

  if (currentIndex >= 0) {
    // The first time this function runs, current is -1
    gsap.set(sections[currentIndex], { zIndex: 0 });
    tl.to(images[currentIndex], { yPercent: -15 * dFactor })
      .set(sections[currentIndex], { autoAlpha: 0 })
  }

  gsap.set(sections[index], { autoAlpha: 1, zIndex: 1 });
  tl.fromTo([outerWrappers[index], innerWrappers[index]], { 
      yPercent: i => i ? -100 * dFactor : 100 * dFactor
    }, { 
      yPercent: 0 
    }, 0)
    .fromTo(images[index], { yPercent: 15 * dFactor }, { yPercent: 0 }, 0)
  currentIndex = index;
}

Observer.create({
  type: "wheel",
  wheelSpeed: -1,
  onDown: () => !animating && gotoSection(currentIndex - 1, -1),
  onUp: () => !animating && gotoSection(currentIndex + 1, 1),
  tolerance: 10,
  preventDefault: true
});

nextBtn.forEach(function (btn) {
  btn.addEventListener('click', (e:Event) => !animating && gotoSection(currentIndex + 1, 1));
}); 

gotoSection(0, 1);

const models = ['chair1.glb', 'chair2.glb'];
const toggleModel = document.querySelector('.toggle-model');
let j = 0;
setInterval(() => toggleModel.setAttribute('src', `assets/models/${models[j++ % 2]}`), 4000);

}
sectionAnimation()

async function formFn() {
  const formSub  = document.getElementById('subscribe');

formSub.addEventListener('submit', (event) => {
  event.preventDefault();
  console.log('success');
  document.getElementById('success-form').click();
  formSub.reset();
});
}

formFn()